.login-container {
  width: 450px;
  max-width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__title {
    color: #fff;
    margin: 50px 0;
    padding: 0;
    font-size: 28px;
  }

  &__options {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
    &-l {
      display: flex;
      & span {
        color: #fff;
        margin-left: 10px;
      }
    }
    &-r span {
      color: #90d5f6;
    }
  }
}
