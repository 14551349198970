.admin-home-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.admin-users-container {
  width: 100%;
  // height: calc(100vh - 120px);
  min-height: 100px;
  margin: 0 30px;
  margin-bottom: 20px;
  border-radius: 15px;
  box-sizing: border-box;
  padding: 10px 20px;
  box-shadow: rgba(0, 0, 0, 20%) 0px 1px 3px, rgb(0 0 0 / 10%) 0px 10px 15px -5px, rgb(0 0 0 / 40%) 0px 7px 7px -5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  // justify-content: center;

  &-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    align-self: flex-end;

    button {
      width: 140px;
      white-space: nowrap;
    }
  }
}

.banner-users {
  box-sizing: border-box;
  background-color: #52a9f5;
  border-radius: 5px;
  padding: 7px 20px;
  display: flex;
  justify-content: space-around;
  gap: 50px;

  &-item {
    border-radius: 20px;
    padding: 5px;
    width: 180px;
  }

  &-side {
    background-color: #fff;
    gap: 10px;
    display: flex;
    align-items: center;

    .title {
      text-align: center;
      font-size: 16px;
      font-weight: bold;
      margin: 0;
      white-space: nowrap;
    }

    .number {
      text-align: center;
      font-size: 12px;
      font-weight: bold;
      margin: 0;
      margin-bottom: 2px;
    }

    .percentage {
      text-align: center;
      margin: 0;
      font-size: 10px;
    }

    &-icon {
      width: 100%;
    }
  }

  &-center {
    color: #fff;

    .title {
      text-align: center;
      font-size: 18px;
      font-weight: bold;
      margin: 0;
      white-space: nowrap;
    }

    .number {
      text-align: center;
      font-size: 24px;
      font-weight: bold;
      margin: 0;
    }
  }

  .flex-1 {
    width: 30%;
  }

  .flex-2 {
    width: 70%;
  }
}

.datagrid-users {
  position: relative;
  min-height: 480px;


  &-header {
    background-color: rgba(214, 214, 214, 0.15);
    border-radius: 15px;
    text-align: center;
    font-weight: bold;
    padding: 16px;
    min-height: 60px;
  } 

  &-cell {
    background-color: rgba(214, 214, 214, 0.15);
    border-radius: 15px;
    text-align: center;
    padding: 16px;
    white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 200px;
  } 

  thead > tr > th {
    background: none;
    border: none;
    padding: 6px;
  }

  tbody > tr > td {
    border: none;
    padding: 6px;

    &:last-child > div {
      background-color: #fff;
    }

  }

  .overview {
    box-sizing: border-box;
    background-color: rgba(53, 53, 53, 0.4);
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    border: none;
    display: flex;
    justify-content: space-evenly;
    border-radius: 10px;

    &-item {
      width: 30%;
      background-color: #fff;
      margin: 15px 0;
      border-radius: 15px;
      box-sizing: border-box;
      padding: 10px 20px;
      box-shadow: rgba(0, 0, 0, 20%) 0px 1px 3px, rgb(0 0 0 / 10%) 0px 10px 15px -5px, rgb(0 0 0 / 40%) 0px 7px 7px -5px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      gap: 20px;
    }

    &__card-title {
      align-self: center;
      font-weight: bold;
    }

    &__card-body {
        display: flex;
        justify-content: center;
        align-items: center;
    }
  }

  &-favExpanded {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 15px;
    grid-auto-flow: dense;
    direction: rtl;

    &-item {
      max-width: 200px;
      
      img,video {
        max-width: 100%;
      }
      &-name {
        font-size: 10px;
        margin-bottom: 0;
      }
    }
  }

  &-pages {
    background-color: #fff;
    border: none;

    &-buttons button {
      cursor: pointer !important;
      color: #000 !important;
      border-color: #000 !important;
    }
  }

  &-expand-icon {
    cursor: pointer;

    &-disabled {
      color: #c2c2c2;
      cursor: default;
    }
  }
}

.overlay {
  // background-color: rgba(53, 53, 53, 0.4);

  &-table {
    background-color: #fff;
  }
}



