.category-card-container {
  width: 100%;
  min-width: 325px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  padding: 18px 0;
  border-bottom: 1px solid #ececec;
  transition: all 300ms;
  cursor: pointer;
  border-radius: 5px;
  &:hover .category-card-container-fab {
    display: flex;
  }
  &-fab {
    transition: all 300ms;
    display: flex;

    height: 100px;
    justify-content: flex-end;
    align-items: center;
    display: none;
    margin-right: 20px;
    &__holder {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
      & button {
        border-style: none;
        background-color: #fff;
        border: 1px solid #f1f1f1;
        height: 35px;
        outline: none;
        width: 35px;
        border-radius: 20px;
        box-shadow: 0px 4px 30px 0px #00000014;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 300ms;
        cursor: pointer;
        &:hover {
          box-shadow: 0px 4px 40px 0px #00000014;
          background-color: #52a9f5;
          & svg {
            color: #fff;
          }
        }
        & svg {
          transition: all 300ms;
          transform: scale(1.2);
        }
      }
      &--delete:hover {
        background-color: #e71c3e !important;
      }
    }
  }
  &-l {
    
    &-image {
    border-radius: 100px;
    width: 60px;
    height: 60px;
    }
    &-free {
      border-radius: 100px;
    border: 2px solid rgba(0, 0, 0, 0.4);
    }
    &-gold {
      border-radius: 100px;
      background: linear-gradient(45deg, #ffec6e 0%, #fcb045 50%);
    border: 2px solid rgba(0, 0, 0, 0.4);
    }
  }

  &-r {
    width: 100%;
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    &-t {
      color: #000;
      font-size: 16px;
      font-weight: 600;
      margin: 0;
      text-transform: capitalize;
    }
    &-b {
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      &__views {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        & img {
          height: 14px;
          width: 14px;
          border-radius: 8px;
          background-color: #f1f1f1;
        }
        & span {
          // color: #52a9f5;
          background-color: #f1f1f1;
          font-size: 14px;
          margin-left: 2px;
          font-weight: 600;
          text-align: left;
          padding: 5px;
          border-radius: 10px;
        }
      }
      &__status {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        & div {
          height: 6px;
          width: 6px;
          border-radius: 3px;
          background-color: #62c662;
        }

        & span {
          color: #2f2f2f;
          font-size: 14px;
          font-weight: 500;
          margin-left: 5px;
        }
      }
    }
  }
}

.category-container {
  width: 370px;
  height: calc(100vh - 120px);
  min-height: 100px;
  margin-bottom: 30px;
  background-color: #fff;
  border-radius: 0 25px 25px 0;
  box-sizing: border-box;
  padding: 30px 23px;
  box-shadow: 10px 10px 28px 0px rgba(0, 0, 0, 0.1);
  & h2 {
    color: #000;
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 0;
  }
  &__cards {
    margin-top: 5px;
    max-height: calc(100vh - 200px);
    display: flex;
    overflow-y: auto;
    overflow-x: hidden;
    flex-direction: column;

      /* ===== Scrollbar CSS ===== */
    /* Firefox */
    & {
      scrollbar-width: auto;
      scrollbar-color: #a8a8a8 #ffffff;
    }

    /* Chrome, Edge, and Safari */
    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      background: #ffffff;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #a8a8a8;
      border-radius: 10px;
      border: 3px solid #ffffff;
    }
  }
}

.filtroo-modal {
  & .ant-modal-content {
    border-radius: 15px !important;
  }
  & .ant-modal-header {
    border-radius: 15px !important;
    border: none !important;
  }
  & .ant-modal-body {
    padding-top: 0 !important;
    & span {
      color: #a7a7a7;
    }
  }

  .edit-switch{
    & span {
      color: #ffffff;
    }
  }
}
